<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 per-page-select">
          <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"/>
          <v-select
              v-model="statusFilter"
              :options="workFlowStatusFilterOptions"
              :reduce="val => val.value"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 userFilter"/>
          <v-select
              v-model="onlyCheckUser"
              :options="onlyCheckUserFilterOptions"
              :reduce="val => val.value"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"/>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
            <header-table :re-fetch-data="reFetchData"></header-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="Kayıt bulunamadı"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value"></id-column-table>
      </template>

      <template #cell(formNo)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(formTypeName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(formDate)="data">
        <date-time-column-table :data="data.value"></date-time-column-table>
      </template>

      <template #cell(userProfilePicture)="data">
        <div class="text-nowrap">
          <b-avatar v-if="data.item.userName" size="32" variant="light-primary" :src="getApiFile(data.value)" :text="avatarText(data.item.userName)"/>
          <span class="ml-1">{{ data.item.userName }}</span>
        </div>
      </template>

      <template #cell(workflowConfirmationTypeName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(checkedUserProfilePicture)="data">
        <div class="text-nowrap">
          <b-avatar v-if="data.item.checkedUserName" size="32" variant="light-primary" :src="getApiFile(data.value)" :text="avatarText(data.item.checkedUserName)"/>
          <span class="ml-1">{{ data.item.checkedUserName }}</span>
        </div>
      </template>

      <template #cell(workflowStatusTypeName)="data">
        <b-badge pill :variant="`light-${workflowStatusVariant(data.item)}`" class="text-capitalize">
          {{ data.value }}
        </b-badge>
      </template>

      <template #cell(createdOn)="data">
        <date-column-table :data="data.value"></date-column-table>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item @click="changeStatusShowModal(data.item, 'APPROVED')">
              <feather-icon icon="CheckIcon"/>
              <span class="align-middle ml-50">Onayla</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatusShowModal(data.item, 'DENIED')">
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">Reddet</span>
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
          <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>
    <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>

    <b-modal v-model="changeStatusModalShow" ref="change-status-modal" title="Durum Değiştir" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="handleChangeStatusModal" centered no-close-on-backdrop>
      <b-form @submit.stop.prevent="submitChangeStatusModal" ref="refFormObserver">
        <b-form-group label="* Durum">
          <v-select v-model="changeStatusId" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
        </b-form-group>
        <b-form-group label="Açıklama">
          <b-form-textarea v-model="changeStatusDescription" placeholder="Açıklama" trim/>
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card>

</template>

<script>
import {BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay, BModal, BCardText, BForm, BFormGroup, BFormTextarea, BFormInvalidFeedback,} from 'bootstrap-vue'
import {FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable, HeaderTable} from '@/components/Table'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import Overlay from "@/components/Overlay.vue"
import Preview from "@/views/form/waiting/Preview.vue"
import {avatarText, getApiFile, workflowStatusVariant, workFlowStatusFilterOptions} from "@core/utils/filter"
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue"
import Vue from "vue"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    BForm,
    DateTimeColumnTable,
    BCardText,
    BModal,

    HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable, Preview,
    DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable,

    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
    BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

    vSelect,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      onlyCheckUser,
      statusFilter,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)


    const onlyCheckUserFilterOptions = [
      {label: 'Tümü', value: false},
      {label: 'Gelen Talepler', value: true},
    ]

    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const selectedRecord = ref(null)
    const statusOptions = ref([])
    const changeStatusId = ref(null)
    const changeStatusDescription = ref(null)
    const changeStatusModalShow = ref(false)
    const changeStatusShowModal = (dataItem, statusValue) => {
      selectedRecord.value = dataItem
      statusOptions.value = []
      changeStatusDescription.value = null;

      statusOptions.value.push({label: 'Onaylandı', value: 'APPROVED'})
      statusOptions.value.push({label: 'Reddedildi', value: 'DENIED'})
      changeStatusId.value = statusValue

      changeStatusModalShow.value = true
    }
    const handleChangeStatusModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitChangeStatusModal()
    }
    const submitChangeStatusModal = () => {

      if (changeStatusId.value === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Durum seçimi yapılmalıdır!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        return
      }

      busy.value = true
      store.dispatch('store/updateStatus', {
        id: selectedRecord.value.id,
        workflowStatusTypeId: changeStatusId.value,
        description: changeStatusDescription.value,
      }).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Durum güncellendi.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      selectedRecord.value = null
      changeStatusId.value = null
      changeStatusModalShow.value = false
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isPreviewModal,
      dataPreviewRecord,
      changeStatusModalShow,
      changeStatusId,
      statusOptions,
      changeStatusDescription,
      refFormObserver,
      onlyCheckUser,
      onlyCheckUserFilterOptions,
      statusFilter,

      workFlowStatusFilterOptions,

      getValidationState,

      fetchList,
      reFetchData,
      previewRecord,
      getApiFile,
      avatarText,
      workflowStatusVariant,
      handleChangeStatusModal,
      submitChangeStatusModal,
      changeStatusShowModal,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
