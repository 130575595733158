<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      scrollable
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="huge">

    <b-row v-if="previewData !== null">
      <b-col cols="12" md="5" lg="5" class="border-right">
        <div class="d-flex mb-1 border-bottom pb-1">
          <feather-icon icon="InfoIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50" v-if="formType === 'MEETING_FORM'">Toplantı Daveti Bilgileri</h5>
          <h5 class="mb-0 mt-0 ml-50" v-if="formType !== 'MEETING_FORM'">Form Bilgileri</h5>
        </div>
        <table class="mt-2 mt-xl-0 w-100" v-if="formType === 'LEAVE_FORM'">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Form No</span></th>
            <td class="pb-50">{{ previewData.formDetails.formNo }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kullanıcı</span></th>
            <td class="py-50">{{ previewData.formDetails.userName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">İzin Tipi</span></th>
            <td class="py-50">{{ previewData.formDetails.leaveTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Başlangıç Tarihi</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.startDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Bitiş Tarihi</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.endDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">İşbaşı Tarihi</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.returnDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">İzin Süresi (Gün)</span></th>
            <td class="py-50">{{ previewData.formDetails.leaveDurationInDays }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kalan Gün</span></th>
            <td class="py-50" v-if="previewData.formDetails.remainingDays !== null">{{ previewData.formDetails.remainingDays }}</td>
            <td class="py-50" v-if="previewData.formDetails.remainingDays === null">-</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Yerine Bakacak Kişi</span></th>
            <td class="py-50">{{ previewData.formDetails.alternativeUserName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">İzinde Ulaşılacak Telefon</span></th>
            <td class="py-50">{{ previewData.formDetails.phoneOnLeave }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">İzinde Olacağı Adres</span></th>
            <td class="py-50">{{ previewData.formDetails.addressOnLeave }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
            <td class="py-50">{{ previewData.formDetails.description }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">
              <b-badge pill :variant="`light-${recordFormStatusVariant(previewData.formDetails)}`" class="text-capitalize">
                {{ previewData.formDetails.formStatusName }}
              </b-badge>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr>
            <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.modifiedOn"></date-time-column-table>
            </td>
          </tr>
        </table>
        <table class="mt-2 mt-xl-0 w-100" v-if="formType === 'EXPENSE_FORM'">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Form No</span></th>
            <td class="pb-50">{{ previewData.formDetails.formNo }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kullanıcı</span></th>
            <td class="py-50">{{ previewData.formDetails.userName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Belge Tarihi</span></th>
            <td class="py-50">
              <date-column-table :data="previewData.formDetails.docDate"></date-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Masraf Tipi</span></th>
            <td class="py-50">{{ previewData.formDetails.expenseTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">KDV</span></th>
            <td class="py-50">{{ previewData.formDetails.totalVat }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Genel Toplam</span></th>
            <td class="py-50">{{ previewData.formDetails.totalNet }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Para Birimi</span></th>
            <td class="py-50">{{ previewData.formDetails.currencyTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
            <td class="py-50">{{ previewData.formDetails.description }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">
              <b-badge pill :variant="`light-${recordFormStatusVariant(previewData.formDetails)}`" class="text-capitalize">
                {{ previewData.formDetails.formStatusName }}
              </b-badge>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr>
            <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.modifiedOn"></date-time-column-table>
            </td>
          </tr>
        </table>
        <table class="mt-2 mt-xl-0 w-100" v-if="formType === 'ADVANCE_FORM'">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Form No</span></th>
            <td class="pb-50">{{ previewData.formDetails.formNo }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kullanıcı</span></th>
            <td class="py-50">{{ previewData.formDetails.userName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Talep Türü</span></th>
            <td class="py-50">{{ previewData.formDetails.advanceFormTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Tutar</span></th>
            <td class="py-50">{{ previewData.formDetails.total }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Para Birimi</span></th>
            <td class="py-50">{{ previewData.formDetails.currencyTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Avans Nedeni</span></th>
            <td class="py-50">{{ previewData.formDetails.advanceFormReasonTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Taksit Sayısı</span></th>
            <td class="py-50">{{ previewData.formDetails.advanceFormInstallmentName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Ödeme Ay</span></th>
            <td class="py-50">{{ previewData.formDetails.paymentMonth }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Ödeme Yıl</span></th>
            <td class="py-50">{{ previewData.formDetails.paymentYear }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
            <td class="py-50">{{ previewData.formDetails.description }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">
              <b-badge pill :variant="`light-${recordFormStatusVariant(previewData.formDetails)}`" class="text-capitalize">
                {{ previewData.formDetails.formStatusName }}
              </b-badge>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr>
            <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.modifiedOn"></date-time-column-table>
            </td>
          </tr>
        </table>
        <table class="mt-2 mt-xl-0 w-100" v-if="formType === 'MEETING_FORM'">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Konu</span></th>
            <td class="pb-50">{{ previewData.formDetails.title }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kategori</span></th>
            <td class="py-50">{{ previewData.formDetails.meetingCategoryName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Başlangıç Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.startDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Bitiş Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.endDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">İşyeri</span></th>
            <td class="py-50">{{ previewData.formDetails.workplaceName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Toplantı Salonu</span></th>
            <td class="py-50">{{ previewData.formDetails.meetingRoomName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Toplantı Tipi</span></th>
            <td class="py-50">{{ previewData.formDetails.meetingTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Hatırlatma</span></th>
            <td class="py-50">{{ previewData.formDetails.meetingRemindingTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Toplantı İçeriği</span></th>
            <td class="py-50">{{ previewData.formDetails.description }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">{{ previewData.formDetails.meetingStatusName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.modifiedOn"></date-time-column-table>
            </td>
          </tr>
        </table>
        <table class="mt-2 mt-xl-0 w-100" v-if="formType === 'OVERWORK_FORM'">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Form No</span></th>
            <td class="pb-50">{{ previewData.formDetails.formNo }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Form Tipi</span></th>
            <td class="py-50">{{ previewData.formDetails.users != null && previewData.formDetails.users.length > 0 ? 'Bağlı Çalışanlar Adına' : 'Kendi Adına' }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kullanıcı</span></th>
            <td class="py-50">{{ previewData.formDetails.userName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Fazla Mesai Nedeni</span></th>
            <td class="py-50">{{ previewData.formDetails.overWorkFormReasonTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Vardiya</span></th>
            <td class="py-50">{{ previewData.formDetails.shiftTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Başlangıç Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.startDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Bitiş Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.endDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Mesai Süresi</span></th>
            <td class="py-50">{{ previewData.formDetails.durationInHours }} Saat</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
            <td class="py-50">{{ previewData.formDetails.description }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">
              <b-badge pill :variant="`light-${recordFormStatusVariant(previewData.formDetails)}`" class="text-capitalize">
                {{ previewData.formDetails.formStatusName }}
              </b-badge>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Çalışanlar</span></th>
            <td class="py-50">
              <b-avatar-group size="33">
                <b-avatar v-for="user in previewData.formDetails.users" :key="user.userId" v-b-tooltip.hover.bottom="user.fullName" :src="getApiFile(user.profilePicture)" :text="avatarText(user.fullName)" class="pull-up" variant="primary"/>
              </b-avatar-group>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr>
            <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.formDetails.modifiedOn"></date-time-column-table>
            </td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" md="7" lg="7" class="border-right">
        <div class="d-flex mb-1 border-bottom pb-1">
          <feather-icon icon="InfoIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50" v-if="formType === 'MEETING_FORM'">Katılımcı Onay Durumları</h5>
          <h5 class="mb-0 mt-0 ml-50" v-if="formType !== 'MEETING_FORM'">Onay Süreci</h5>
        </div>
        <app-timeline>
          <app-timeline-item variant="primary" class="pb-3">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <b-media>
                <template #aside>
                  <b-avatar :src="getApiFile(previewData.formDetails.userPicture)" :text="avatarText(previewData.formDetails.userName)" variant="light-primary"/>
                </template>
                <h6 class="mb-0 pb-0">{{ previewData.formDetails.userName }}</h6>
                <span class="text-muted">{{ previewData.formDetails.userPositionName }}</span>
              </b-media>
              <small class="text-muted">Oluşturuldu</small>
            </div>
          </app-timeline-item>
          <app-timeline-item v-for="(step, index) in previewData.workflowSteps" :key="index" :variant="step.workflowStatusTypeId === 'WAITING' ? 'warning' : step.workflowStatusTypeId === 'PRE_WAITING' ? 'secondary' : step.workflowStatusTypeId === 'APPROVED' ? 'success' : 'danger'" class="pb-3">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <b-media>
                <template #aside>
                  <b-avatar :src="getApiFile(step.checkedUserProfilePicture)" :text="avatarText(step.checkedUserName)" variant="light-primary"/>
                </template>
                <h6 class="mb-0 pb-0">{{ step.checkedUserName }}</h6>
                <span class="text-muted">{{ step.checkedUserPositionName }}</span>
              </b-media>
              <small class="text-muted">{{ step.workflowStatusTypeName }}</small>
            </div>
          </app-timeline-item>
        </app-timeline>
      </b-col>
    </b-row>

    <div class="my-1"></div>

    <template #modal-footer="">
      <b-row class="d-flex justify-content-end align-items-center">
        <b-button class="mr-1" variant="outline-secondary" @click="closeModal">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Kapat</span>
        </b-button>
      </b-row>
    </template>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BAvatar, BAvatarGroup, BBadge, BButton, BCol, BCollapse, BImg, BListGroup, BListGroupItem, BMedia, BModal, BRow, VBTooltip} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat, recordFormStatusVariant} from "@core/utils/filter"
import {ActiveColumnTable, DateTimeColumnTable} from "@/components/Table";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {avatarText, getApiFile} from "@core/utils/filter"
import DateColumnTable from "@/components/Table/DateColumnTable.vue";

export default {
  components: {
    BAvatarGroup,
    DateColumnTable,
    BListGroupItem,
    BListGroup,
    BCollapse,
    BAvatar,
    BMedia,
    BImg,
    AppTimelineItem,
    AppTimeline,
    BCol,
    DateTimeColumnTable,
    ActiveColumnTable,
    Overlay,

    BRow,
    BButton,
    BModal,
    BBadge
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref(null);
    const formType = ref(props.dataRecord.formTypeId)

    watch(() => props.dataRecord, (dataValue) => {
      formType.value = dataValue.formTypeId

      if (dataValue.id !== undefined) {
        busy.value = true
        store.dispatch('store/previewItem', {formTypeId: dataValue.formTypeId, formId: dataValue.formId, userId: dataValue.userId}).then(response => {
          previewData.value = response.data.data

          if (formType === 'MEETING_FORM') {
            previewData.value.formDetails.userPicture = response.data.data.formDetails.ownerPicture
            previewData.value.formDetails.userName = response.data.data.formDetails.ownerName
            previewData.value.formDetails.userPositionName = response.data.data.formDetails.ownerPositionName
          }

        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const closeModal = () => {
      emit('update:is-preview-modal', false)
      emit('update:data-record', {})
    }

    return {
      busy,
      previewData,
      formType,

      closeModal,
      recordStatusFormat,
      recordStatusVariant,
      recordFormStatusVariant,
      avatarText,
      getApiFile
    }
  },
}
</script>

<style lang="scss">
.modal .modal-huge {
  max-width: 1000px;
  width: 1000px;
}
</style>