import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/workflowstepforms', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/workflowstepforms/view', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateStatus(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/workflowstepforms/update-status', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
